@tailwind base;
.card {
  @apply bg-gr-gray-50 rounded-[12px] p-[20px] shadow-sm;
}
@tailwind components;
@tailwind utilities;
@layer components {
  body {
    margin: 0;
    @apply bg-gr-secondary-50 sm:bg-gr-secondary-50 md:bg-gr-secondary-50 lg:bg-gr-secondary-50 xl:bg-gr-secondary-50 2xl:bg-gr-secondary-50;
  }
  * {
    font-family: inherit;
  }
}

.gr-login-form-input .mantine-InputWrapper-root {
  @apply space-y-[4px];
}
.gr-login-form-input .mantine-Input-wrapper {
  @apply min-w-full lg:min-w-[350px] min-h-[48px] bg-gr-gray-100 border border-gr-gray-100  rounded-[8px] outline-none focus-within:border-gr-gray-300 overflow-hidden;
}
.gr-login-form-input .mantine-Input-input,
.gr-login-form-input .mantine-PasswordInput-innerInput {
  @apply min-w-full lg:min-w-[350px] min-h-[48px] border-none bg-transparent pr-[10px] pl-[40px];
}

.gr-login-form-input .mantine-Input-section[data-position="left"] {
  @apply left-[5px];
}
.gr-login-form-input .mantine-Input-section[data-position="right"] {
  @apply right-[5px];
}
.gr-settings-search .mantine-Input-input {
  @apply min-h-[60px] max-h-[60px] h-[60px] text-[26px] placeholder:text-[26px];
}

.lead-details-profile-tabs {
  @apply text-[12px] font-[400] text-gr-gray-900;
}

.lead-details-profile-tabs[aria-selected="true"] {
  @apply text-[12px] font-[600] text-gr-primary-700;
}

.gr-task-comment-input .mantine-TextInput-wrapper {
  @apply min-h-[40px] rounded-[8px] outline-none overflow-hidden;
}
.gr-task-comment-input .mantine-Input-input {
  @apply min-h-[40px] bg-gr-gray-100 border border-gr-gray-200  rounded-[8px] outline-none focus-within:border-gr-gray-300 overflow-hidden;
}

.gr-task-form .mantine-Select-wrapper,
.gr-task-form .mantine-DateTimePicker-wrapper,
.gr-task-form .mantine-MultiSelect-wrapper {
  @apply min-h-[40px] rounded-[8px] outline-none overflow-hidden;
}
.gr-task-form .mantine-Select-input,
.gr-task-form .mantine-DateTimePicker-input,
.gr-task-form .mantine-MultiSelect-input {
  @apply min-h-[40px] rounded-[8px] bg-gr-gray-50 border border-gr-gray-200  outline-none focus-within:border-gr-gray-300 overflow-hidden;
}

.gr-task-form .mantine-Textarea-wrapper {
  @apply rounded-[8px] h-[unset] outline-none overflow-hidden !important;
}
.gr-task-form .mantine-Textarea-input {
  @apply rounded-[8px] h-[unset] bg-gr-gray-50 border border-gr-gray-200  outline-none focus-within:border-gr-gray-300 overflow-hidden !important;
}

.gr-switch .mantine-Switch-track {
  @apply cursor-pointer;
}
.gr-formBuilder-input .mantine-Input-input {
  @apply border border-gr-gray-300 h-[40px] bg-gr-gray-100 rounded-[8px] text-[15px] p-[16px] font-[400] placeholder:text-gr-gray-500 text-gr-gray-700;
}
.gr-formBuilder-input .mantine-Input-input:not(:disabled) {
  @apply focus:border-gr-gray-500 hover:border-gr-gray-500 focus:shadow-none;
}
.gr-formBuilder-input .mantine-TextInput-label {
  @apply text-[14px] text-gr-gray-500 font-[600];
}
.gr-formBuilder-input .mantine-Checkbox-label {
  @apply text-[12px] text-gr-gray-900 font-[400];
}
.gr-form .mantine-TextInput-label,
.gr-form .mantine-Textarea-label,
.gr-form .mantine-Checkbox-label {
  @apply text-[14px] text-gr-gray-700 font-[600];
}
.gr-formBuilder-input .mantine-Checkbox-input {
  @apply cursor-pointer;
}
.gr-form .mantine-Input-input {
  @apply h-[40px]  rounded-[8px] bg-gr-gray-50 border border-gr-gray-200 outline-none focus-within:border-gr-gray-300 overflow-hidden;
}
.gr-form .mantine-Textarea-input {
  @apply h-[unset];
}

.gr-form.disabled .mantine-Select-wrapper {
  @apply rounded-[8px] bg-gr-gray-50 border border-gr-gray-200;
}
.gr-form.disabled .mantine-Select-input {
  @apply rounded-[8px] bg-gr-gray-50 border border-gr-gray-200 outline-none focus-within:border-gr-gray-300 overflow-hidden pointer-events-none cursor-not-allowed opacity-[0.5];
}

.gr-rich .mantine-RichTextEditor-content > div {
  @apply min-h-[280px] max-h-[280px] overflow-y-auto;
}
.gr-rich-viewer .mantine-RichTextEditor-content {
  @apply bg-transparent;
}

.gr-rich-viewer .mantine-RichTextEditor-content > div {
  @apply text-[14px] text-gr-gray-900;
}

.gr-cdt-edit-form .mantine-Input-input {
  @apply h-[40px];
}

.gr-upload-form .mantine-Input-input {
  @apply h-[40px] placeholder:text-[14px] placeholder:font-[400] placeholder:text-gr-gray-500;
}

.gr-lead-edit-form .mantine-Checkbox-label {
  @apply text-[12px] text-gr-gray-900 font-[400];
}

.gr-lead-edit-form .mantine-TextInput-label,
.gr-lead-edit-form .mantine-Textarea-label,
.gr-lead-edit-form .mantine-Checkbox-label {
  @apply text-[14px] text-gr-gray-700 font-[600] mb-1;
}
.gr-lead-edit-form .mantine-Input-input {
  @apply h-[40px] rounded-[8px] bg-gr-gray-50 border border-gr-gray-300 outline-none focus-within:border-gr-gray-400 text-gr-gray-900;
}

.gr-lead-edit-form .gr-lead-edit-form-left .mantine-Select-wrapper {
  @apply flex justify-between relative z-40;
}
.gr-lead-edit-form
  .gr-lead-edit-form-left
  .mantine-Select-wrapper
  .mantine-Input-section[data-position="right"] {
  @apply start-[unset];
}

.gr-lead-edit-form .gr-lead-edit-form-left .mantine-Select-input {
  @apply px-2 h-full rounded-none bg-transparent border-none relative;
}

.lead-details-request-tabs {
  @apply text-[12px] font-[400] text-gr-gray-900;
}

.lead-details-request-tabs[aria-selected="true"] {
  @apply text-[12px] font-[600] text-gr-primary-700;
}

.mapper-input.is-dragging-over .mantine-Input-input {
  @apply border-dashed border border-gr-primary;
}
.gr-timeline{
  @apply max-h-[calc(100vh-320px)]
}
.gr-timeline .mantine-Timeline-itemBullet {
  @apply border-gr-gray-300 bg-gr-gray-0 overflow-hidden;
}

.gr-task-form.request-note-textarea .mantine-Input-input {
  @apply text-[16px];
}

.gr-textarea .mantine-Input-input {
  @apply text-[16px];
}
.gr-textarea .mantine-Textarea-label {
  --input-label-size: var(--mantine-font-size-md);
}
.gr-formBuilder-input .mantine-DatePickerInput-input {
  @apply py-[unset] !important;
}
.mantine-Textarea-wrapper,
.mantine-Textarea-input {
  @apply h-[unset] !important;
}
.add-lead-menu-item{
  @apply w-full h-[40px] px-4 py-2 gap-2 rounded-r-[12px] cursor-pointer flex items-center hover:bg-gr-primary-50 hover:border-l-[3px] hover:border-gr-primary-400 transition-all duration-200;
}
.add-lead-menu-item.is-active{
  @apply bg-gr-primary-50 border-l-[3px] border-gr-primary-400;
}